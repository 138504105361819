<template>
<div id="app">
    <v-snackbar v-model="snackbartest.visible" auto-height :color="snackbartest.color" :multi-line="snackbartest.mode === 'multi-line'" :timeout="snackbartest.timeout" :top="snackbartest.position === 'top'">
        <v-layout align-center pr-4>
            <v-icon class="pr-3" dark large>{{ snackbartest.icon }}</v-icon>
            <v-layout column>
                <div>
                    <strong>{{ snackbartest.title }}</strong>
                </div>
                <div>{{ snackbartest.text }}</div>
            </v-layout>
        </v-layout>
        <v-btn v-if="snackbartest.timeout === 0" icon @click="snackbartest.visible = false">
            <v-icon>clear</v-icon>
        </v-btn>
    </v-snackbar>

 <v-card
    class="mx-auto"
    max-width="344"
    outlined
  >
    <v-list-item three-line>
      <v-list-item-content>
        <div class="text-overline mb-4">
          OVERLINE
        </div>
        <v-list-item-title class="text-h5 mb-1">
          Headline 5
        </v-list-item-title>
        <v-list-item-subtitle>Greyhound divisely hello coldly fonwderfully</v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-avatar
        tile
        size="80"
        color="grey"
      ></v-list-item-avatar>
    </v-list-item>

    <v-card-actions>
      <v-btn
        outlined
        rounded
        text
      >
        Button
      </v-btn>
    </v-card-actions>
  </v-card>

    <v-row>
        <v-col cols="2">
            <v-select :items="listpays2" v-model="selectpays2" dense outlined item-value="id" @change="paysSearch2" label="Recherche des Pays">
                <template slot="selection" slot-scope="data">
                    {{ data.item.code_pays }} - {{ data.item.libelle }}
                </template>
                <template slot="item" slot-scope="data">
                    {{ data.item.code_pays }} - {{ data.item.libelle }}
                </template>
            </v-select>

        </v-col>
        <v-col cols="3">
            <v-select :items="listcible2" v-model="selectcible2" @change="cibleSearch2" dense item-value="id" outlined label="Liste des Cibles des ODD">
                <template slot="selection" slot-scope="data">
                    {{ data.item.code }} - {{ data.item.description }}
                </template>
                <template slot="item" slot-scope="data">
                    {{ data.item.code }} - {{ data.item.description }}
                </template>
            </v-select>

        </v-col>
        <v-col cols="3">
            <v-select :items="listindicateur2" v-model="selectindicateur2" @change="indicateurSearch2" dense outlined item-value="id" label="Recherche des Indicateurs ODD extrats">
                <template slot="selection" slot-scope="data">
                    {{ data.item.code }} - {{ data.item.libelle }}
                </template>
                <template slot="item" slot-scope="data">
                    {{ data.item.code }} - {{ data.item.libelle }}
                </template>
            </v-select>

        </v-col>
        <v-col cols="3">
            <v-btn color="green" dark class="mb-2" @click="initialize">Actualiser</v-btn>

        </v-col>
    </v-row>

    <v-data-table :headers="headers" :items="list" sort-by="code" class="elevation-1">

        <template v-slot:top>
            <v-toolbar flat>
                <v-toolbar-title>LITSE DES MODALITES EXTRATS</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-dialog v-model="dialog" max-width="500px">

                    <v-snackbar v-model="snackbar" :multi-line="multiLine">
                        {{ text }}

                        <template v-slot:action="{ attrs }">
                            <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
                                Fermer
                            </v-btn>
                        </template>
                    </v-snackbar>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on" @click="nouveau()">
                            Nouveau
                        </v-btn>
                    </template>
                    <v-card>
                        <v-card-title>
                            <span class="text-h5">{{ formTitle }}</span>
                        </v-card-title>

                        <v-card-text>
                            <v-container>
                                <v-form ref="form" v-model="valid" lazy-validation>
                                    <v-select :items="listpays" v-model="selectpays" @change="paysSearch" dense item-value="id" outlined label="Liste des pays">
                                        <template slot="selection" slot-scope="data">
                                            {{ data.item.code_pays }} - {{ data.item.libelle }}
                                        </template>
                                        <template slot="item" slot-scope="data">
                                            {{ data.item.code_pays }} - {{ data.item.libelle }}
                                        </template>
                                    </v-select>

                                    <v-select :items="listcible" v-model="selectcible" @change="cibleSearch" dense item-value="id" outlined label="Liste des cibles des ODD">
                                        <template slot="selection" slot-scope="data">
                                            {{ data.item.code }} - {{ data.item.description }}
                                        </template>
                                        <template slot="item" slot-scope="data">
                                            {{ data.item.code }} - {{ data.item.description }}
                                        </template>
                                    </v-select>

                                    <v-select :items="listindicateur" v-model="selectindicateur" dense item-value="id" outlined label="Indicateur ODD">
                                        <template slot="selection" slot-scope="data">
                                            {{ data.item.code }} - {{ data.item.libelle }}
                                        </template>
                                        <template slot="item" slot-scope="data">
                                            {{ data.item.code }} - {{ data.item.libelle }}
                                        </template>
                                    </v-select>
                                    <v-text-field v-model="libelle" label="Libelle modalité *" dense outlined required></v-text-field>
                                    <v-text-field v-model="code_colors" label="Libelle code couleur *" dense outlined required></v-text-field>

                                </v-form>

                            </v-container>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="dialog=false">
                                Fermer
                            </v-btn>
                            <v-btn color="blue darken-1" text @click="save">
                                Enregistrer
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="dialogDelete" max-width="500px">
                    <v-card>
                        <v-card-title class="text-h5">Voullez vous vraiment supprimer?</v-card-title>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="dialogDelete=false">Annuller</v-btn>
                            <v-btn color="blue darken-1" text @click="deleteData">Confirmer</v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-toolbar>
        </template>
        <template v-slot:[`item.indicateur_extrats`]="{ item }">
            {{ item.indicateur_extrats.code }} - {{ item.indicateur_extrats.libelle }}
        </template>
        <template v-slot:[`item.code_colors`]="{ item }">
            <v-chip :color="getColor(item.code_colors)" dark>
                {{ item.code_colors }}
            </v-chip>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" color="green" @click="editItem(item)">
                mdi-pencil
            </v-icon>
            <v-icon color="red" class="mr-2" small @click="deleteItem(item)">
                mdi-delete
            </v-icon>
        </template>
        <template v-slot:no-data>
            <v-btn color="primary" @click="initialize">
                Actualiser la liste
            </v-btn>
        </template>
    </v-data-table>

</div>
</template>

<script>
import api from '../../../../serviceApi/apiService'

export default {
    props: {
        informationData: {
            type: Object,
            default: () => {},
        },
    },
    data: () => ({
        snackbartest: false,
        text: `message`,
        textsnackbar: 'messages',

        multiLine: true,
        snackbar: false,
        snackbars: false,
        text: `message`,
        textsnackbar: 'messages',
        dialog: false,
        dialogDelete: false,
        headers: [{
                text: 'Actions',
                value: 'actions',
                sortable: false,
                divider: true,
                width: '6%'
            },
            {
                text: 'ID',
                value: 'id',
                divider: true,
                width: '5%'
            },
            {
                text: 'MODALITE ',
                value: 'libelle',
                divider: true,
                width: '25%'
            },
            {
                text: 'CODE COULEUR ',
                value: 'code_colors',
                divider: true,
                width: '25%'
            },
            {
                text: 'INDICATEUR ODD EXTRAT',
                value: 'indicateur_extrats',
                divider: true,
                width: '40%'
            },

        ],
        formTitle: 'Formulaire de Modalité',
        list: [],
        list2: [],
        listindicateur3: [],
        listindicateur: [],
        selectindicateur: '',

        listindicateur2: [],
        selectindicateur2: '',

        listcible3: [],
        listcible: [],
        selectcible: '',

        listcible2: [],
        selectcible2: '',

        listpays: [],
        selectpays: 0,

        listpays2: [],
        selectpays2: 0,

        libelle: '',
        code_colors: '',
        valider: '0',
        id: '0',
        valid: true,

        textetat: false,
        dateFormatted: null,
        messages: '',
        textmessage:'',

    }),

    methods: {
        //=====Affichages  liste des odds==========
        readAll: async function () {
            const data = await api.readAll('parametre/all-modalite-odd-extrat');
            this.list = data.list;
            this.list2 = data.list;
        },
        readAll_pays_cible_indicateur: async function () {
            const data = await api.readAll('global-indicateur-cible-indicateur-extrat-pays/all');

            this.listpays = data.list_pays;
            this.listpays2 = data.list_pays;

            this.listcible = data.list_cible;
            this.listcible2 = data.list_cible;
            //this.listcible3 = data.list_cible;

            //  this.listindicateur = data.list_indicateur_extrat;
            //this.listindicateur2 = data.list_indicateur_extrat;
            this.listindicateur3 = data.list_indicateur_extrat;

        },

        readAllRecherche: async function () {
            const data = await api.readAll('parametre/all-modalite-odd-extrat/' + this.selectindicateur2);
            this.list = data.list;
        },

        readIndicateurAll: async function () {
            const data = await api.readAll('indicateur-odd-extrat/all');
            this.listindicateur = data.list;
            this.listindicateur2 = data.list;

        },

        deleteData: async function () {
            let fd = new FormData();
            fd.append('id', this.id);
            const res = await api.createUpdatedata('parametre/delete-modalite-odd-extrat', fd);
             if (res.status == 200) {
                    this.textmessage = res.message;
                    this.snackbartest = {
                        color: "green",
                        icon: "mdi-check-all",
                        mode: "multi-line",
                        position: "top",
                        timeout: 2500,
                        title: "Information",
                        text: this.textmessage,
                        visible: true
                    };
                   // this.dialogDelete = false;
                } else {
                    this.textmessage = res.message;
                    this.snackbartest = {
                        color: "red",
                        icon: "mdi-check-alert",
                        mode: "multi-line",
                        position: "top",
                        timeout: 2500,
                        title: "Information",
                        text: this.textmessage,
                        visible: true
                    };

                }
                 this.dialogDelete = false;
                this.readAll();
            },

        createUpdatedata: async function () {

            let fd = new FormData();
            fd.append('libelle', this.libelle);
            fd.append('code_colors', this.code_colors);
            fd.append('id_indicateurodd_extrat', this.selectindicateur);

            if (this.textetat) {

                const res = await api.createUpdatedata('parametre/update-modalite-odd-extrat/' + this.id, fd);
                if (res.status == 200) {
                    this.textmessage = res.message;
                    this.snackbartest = {
                        color: "green",
                        icon: "mdi-check-all",
                        mode: "multi-line",
                        position: "top",
                        timeout: 2500,
                        title: "Information",
                        text: this.textmessage,
                        visible: true
                    };
                    this.dialog = false;
                } else {
                    this.textmessage = res.message;
                    this.snackbartest = {
                        color: "red",
                        icon: "mdi-check-alert",
                        mode: "multi-line",
                        position: "top",
                        timeout: 2500,
                        title: "Information",
                        text: this.textmessage,
                        visible: true
                    };

                }
            } else {
                const res = await api.createUpdatedata('parametre/add-modalite-odd-extrat', fd);
                if (res.status == 200) {
                    this.textmessage = res.message;
                    this.snackbartest = {
                        color: "green",
                        icon: "mdi-check-all",
                        mode: "multi-line",
                        position: "top",
                        timeout: 2500,
                        title: "Information",
                        text: this.textmessage,
                        visible: true
                    };
                    // this.dialog = false;
                } else {
                    this.textmessage = res.message;
                    this.snackbartest = {
                        color: "red",
                        icon: "mdi-alert-circle-outline",
                        mode: "multi-line",
                        position: "top",
                        timeout: 2500,
                        title: "Information",
                        text: this.textmessage,
                        visible: true
                    };

                }
            }

            this.clear();
            this.readAll();
        },

        paysSearch() {

            // this.listcible = [];
            // this.listcible = this.listcible3.filter(this.filtrerParID_cible);
        },
        paysSearch2() {

            this.list = [];
            this.listindicateur2 = [];
            this.listindicateur2 = this.listindicateur3.filter(this.filtrerParID_indicateur2);
            this.list = this.list2.filter(this.filtrerParID);
        },

        cibleSearch() {

            this.listindicateur = [];
            this.listindicateur = this.listindicateur3.filter(this.filtrerParID_indicateur);
            //   console.log(this.listindicateur);

        },

        cibleSearch2() {
            this.list = [];
            this.listindicateur2 = [];
            this.listindicateur2 = this.listindicateur3.filter(this.filtrerParID_indicateur2);
            this.list = this.list2.filter(this.filtrerParID);

        },

        indicateurSearch2() {
            this.list = [];
            this.listindicateur2 = [];
            this.listindicateur2 = this.listindicateur3.filter(this.filtrerParID_indicateur2);
            this.list = this.list2.filter(this.filtrerParID2);

        },

        filtrerParID: function (obj) {
            if (obj.indicateur_extrats.id_cibles == this.selectcible2 && obj.indicateur_extrats.id_pays == this.selectpays2) {
                return true;
            } else {
                return false;
            }
        },

        filtrerParID2: function (obj) {
            if (obj.indicateur_extrats.id_cibles == this.selectcible2 && obj.indicateur_extrats.id_pays == this.selectpays2 && obj.indicateur_extrats.id == this.selectindicateur2) {
                return true;
            } else {
                return false;
            }
        },

        filtrerParID_indicateur: function (obj) {
            if (obj.id_cibles == this.selectcible && obj.id_pays == this.selectpays) {
                return true;
            } else {
                return false;
            }
        },

        filtrerParID_indicateur2: function (obj) {
            if (obj.id_cibles == this.selectcible2 && obj.id_pays == this.selectpays2) {
                return true;
            } else {
                return false;
            }
        },
        getColor(item) {
            return item;
        },

        initialize() {
            this.selectpays2 = '';
            this.selectcible2 = '';
            this.selectindicateur2 = '';
            this.readAll();
        },

        nouveau() {
            this.id_indicateur = "0";
            this.textetat = false;
            // this.selectindicateur='0';

        },

        clear() {
            this.code_colors = '';
            // this.selectindicateur = "0";
            this.libelle = "";
            this.textetat = false;
            this.textetat = false;
        },
        deleteItem(item) {
            this.id = item.id;
            this.dialogDelete = true;

        },
        editItem(item) {
            this.id = item.id;
            this.selectcible = item.indicateur_extrats.id_cibles;
            this.selectindicateur = item.id_indicateurodd_extrat;
            this.code_colors = item.code_colors;
            this.libelle = item.libelle;
            this.textetat = true;
            this.dialog = true;
        },

        deleteItemConfirm() {
            this.deleteData();
            this.readAll();
            this.dialogDelete = false;
        },

        save() {
            if (this.libelle == '' || this.code_colors == '') {
                this.textmessage = "Veuillez remplir tous les champs";
                this.snackbartest = {
                    color: "red",
                    icon: "mdi-alert",
                    mode: "multi-line",
                    position: "top",
                    timeout: 2500,
                    title: "Information",
                    text: this.textmessage,
                    visible: true
                };

                return;
            }

            if (this.code_colors == '') {
                this.textmessage = "Veuillez Inserer code couleur";
                this.snackbartest = {
                    color: "red",
                    icon: "mdi-alert",
                    mode: "multi-line",
                    position: "top",
                    timeout: 2500,
                    title: "Information",
                    text: this.textmessage,
                    visible: true
                };
                return;
            }
            this.createUpdatedata();

        },

        parcoure: function filtrerParID(obj) {
            if (obj.id_cibles == this.selectcible) {
                //console.log(obj.libelle);
                return true;
            } else {

                return false;
            }
        },

    },
    mounted() {
        this.readAll();
        this.readAll_pays_cible_indicateur();
        //this.readindicateurAll();
    },

}
</script>
